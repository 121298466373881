.page, .single-post {
  article {
    .wrapper {
      .wpcf7 {
        padding: 30px 0px;
        form {
          & > p {
            display: flex;
            flex-direction: column;
            align-items: center;
            .wpcf7-submit {
              font-family: 'Open Sans',sans-serif;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              font-size: 12px;
              letter-spacing: .05em;
              text-transform: uppercase;
              color: #fff;
              background: #ff8300;
              border-radius: 25px;
              padding: 10px 15px;
              width: 150px;
              margin-bottom: 10px;
              display: inline-block;
              border: none;
              cursor: pointer;
              color: #fff !important;
              &:hover {
                background: #2f80ed;
              }
            }
            span {
              max-width: 100%;
            }
            input {
              margin-bottom: 10px;
              color: #4f4f4f !important;
              padding: 4px;
              max-width: 100%;
            }
            .tourtextarea {
              width: 310px;
              max-width: 100%;
              textarea {
                max-width: 100%;
                padding: 4px;
                color: #4f4f4f !important;
                margin-bottom: 10px;
                width: 100%;
              }
            }
          }
          .wpcf7-not-valid-tip {
            margin-top: -15px;
            margin-bottom: 0px;
            font-size: 12px;
          }
          .wpcf7-validation-errors, .wpcf7-mail-sent-ok {
            text-align: center;
            font-size: 14px;
            max-width: 800px;
            display: block;
            margin: 0px auto;
          }
        }
      }
    }
  }
}